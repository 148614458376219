//React
import React from "react";
import PropTypes from "prop-types";

//Lodash
import get from "lodash.get";

// Components
import { FormInputComponent } from "src/components/forms/form_input_component";
import { FormCheckboxComponent } from "src/components/forms/form_checkbox_component";
import { FormInputOptionComponent } from "src/components/forms/form_input_option_component";
import { FormDatePickerComponent } from "src/components/forms/form_date_picker_component";
import { GridRow, GridColumn } from "src/components/grid";

// Helpers
import { formatValues } from "./format_values";
import { evaluatePredicate } from "src/models/assessment_answers";

//Styling
import styles from "./style.module.less";

//Validations
import { dateFormatMatch } from "src/validations";

const questionsAllowedFutureDates = ["nextEvaluationDate", "stopDate"];
/**
 * Render answers for an assessment schema (static/not editible)
 */
export class SchemaAnswersFormComponent extends React.PureComponent {
  static propTypes = {
    assessmentAnswer: PropTypes.object.isRequired,
    assessmentSchema: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    revisionIsFirstInSeries: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  renderQuestionInput(question) {
    const { assessmentSchema, formValues } = this.props;
    const { name, type, required } = question;
    // const requiredQuestion = question.required ? required : null ;
    // const viewParameter = get(question, "viewParameters.control")
    const useFormValue = formValues
      ? formValues
      : this.props.assessmentAnswer.attributes.answersJson;

    const translations = get(
      assessmentSchema,
      "attributes.schemaJson.localizationDictionary.base",
      null
    );

    switch (type) {
      // hidden question
      case 0:
        return;

      // Boolean value
      case 1:
        const opts = [{ key: "Yes", text: "Yes", value: "1" }];
        return (
          <FormInputComponent
            alignmentOverride={true}
            name={name}
            componentType="select"
            emptyDefault={true}
            defaultOption={"Select Option"}
            labelText={translations[`${name}.title`]}
            required={required}
            // validate={requiredQuestion}
          >
            {opts.map(answer => {
              return (
                <FormInputOptionComponent
                  key={answer.key}
                  text={answer.text}
                  value={answer.value}
                />
              );
            })}
          </FormInputComponent>
        );
        return;

      // Integer
      case 2:
        return (
          <FormInputComponent
            alignmentOverride={true}
            name={name}
            componentType="number"
            required={required}
            labelText={translations[`${name}.title`]}
            // validate={requiredQuestion}
            step={1}
          />
        );

      //float Values
      case 3:
        return (
          <FormInputComponent
            name={name}
            alignmentOverride={true}
            componentType="number"
            required={required}
            labelText={translations[`${name}.title`]}
            // validate={requiredQuestion}
            step={0.01}
          />
        );

      // String
      case 4:
        return (
          <FormInputComponent
            alignmentOverride={true}
            name={name}
            componentType="text"
            required={required}
            // validate={requiredQuestion}
            labelText={translations[`${name}.title`]}
            useMultiLine={true}
          />
        );

      // date value
      case 5:
        return (
          <FormDatePickerComponent
            name={name}
            alignmentOverride={true}
            component="input"
            componentType="date"
            required={required}
            placeholder="YYYY-MM-DD"
            value={useFormValue[name] ? new Date(useFormValue[name]) : null}
            maxDate={questionsAllowedFutureDates.includes(name) ? null : new Date()}
            validate={dateFormatMatch}
            labelText={translations[`${name}.title`]}
          />
        );

      // single select list (enumeration)
      case 6:
        const opt = question.enumConstants;
        return (
          <FormInputComponent
            // validate={requiredQuestion}
            name={name}
            alignmentOverride={true}
            componentType="select"
            emptyDefault={true}
            defaultOption={"Select Option"}
            required={required}
            labelText={translations[`${name}.title`]}
          >
            {opt.map(answer => {
              return (
                <FormInputOptionComponent
                  key={answer}
                  text={translations[`${name}.${answer}`]}
                  value={answer}
                />
              );
            })}
          </FormInputComponent>
        );

      // Multi select list (flags)
      case 7:
        return (
          <div>
            <label className={styles["label-text"]}>
              {translations[`${name}.title`]}{" "}
              {required && <span className={styles["required-field"]}>*</span>}
            </label>
            <div className={styles["checkbox-style"]}>
              {question.enumConstants.map(answer => {
                return (
                  <FormCheckboxComponent
                    // validate={requiredQuestion}
                    component="input"
                    componentType="checkbox"
                    key={answer}
                    name={`${name}.${answer}`}
                    value={answer}
                    labelText={translations[`${name}.${answer}`]}
                    checked={useFormValue[name] && useFormValue[name][answer]}
                  />
                );
              })}
            </div>
          </div>
        );
    }
  }

  evaluateQuestion = (formattedValues, predicate) => {
    const { revisionIsFirstInSeries } = this.props;
    return evaluatePredicate({
      answersJson: formattedValues,
      revisionIsFirstInSeries,
      predicate: predicate
    });
  };

  renderViewOnlyField = (name, answer, required) => {
    return (
      <GridRow className={styles["margin-bottom5px"]}>
        <GridColumn sm="3">
          <label className={styles["label-text"]}>
            {name}{" "}
            {required && <span className={styles["required-field"]}>*</span>}
          </label>
        </GridColumn>
        <GridColumn sm="9">
          <p className={styles["view-only-text"]}>{answer}</p>
        </GridColumn>
      </GridRow>
    );
  };

  renderQuestionAndAnswer = (
    enabledQuestion,
    question,
    formattedValues,
    translations
  ) => {
    if (enabledQuestion) {
      return this.renderQuestionInput(question);
    } else {
      let yesOrNoAnswer;
      if (question.type == 1) {
        yesOrNoAnswer =
          formattedValues[question.name] === "0" ? "Not Set" : "Yes";
      }
      const name = translations[`${question.name}.title`];
      const answer =
        translations[`${question.name}.${formattedValues[question.name]}`] ||
        yesOrNoAnswer ||
        "Not Set";
      const required = question.required;
      return this.renderViewOnlyField(name, answer, required);
    }
  };

  render() {
    const { assessmentSchema, assessmentAnswer, formValues } = this.props;

    const schemaJson = get(assessmentSchema, "attributes.schemaJson", null);
    const schemaJsonQuestions = get(schemaJson, "questions", []);
    const answersJson = formValues
      ? formValues
      : assessmentAnswer.attributes.answersJson;
    const formattedValues = formatValues(answersJson);
    const hiddenQuestionName = ["location"];
    const multipleEvaluetionQuestion = [
      "surgicalSutureCount",
      "surgicalStapleCount"
    ];

    const formInputs =
      schemaJson &&
      schemaJsonQuestions.map(question => {
        const visiblePredicate = question.visiblePredicateJson;
        const enabledPredicate = question.enabledPredicateJson;
        const translations = get(
          assessmentSchema,
          "attributes.schemaJson.localizationDictionary.base",
          null
        );
        let visibleQuestion = visiblePredicate
          ? this.evaluateQuestion(formattedValues, visiblePredicate)
          : true;
        let enabledQuestion = enabledPredicate
          ? this.evaluateQuestion(formattedValues, enabledPredicate)
          : true;

        if (multipleEvaluetionQuestion.includes(question.name)) {
          visibleQuestion =
            visibleQuestion && formattedValues.woundType == "surgical";
        }

        if (hiddenQuestionName.includes(question.name)) {
          enabledQuestion = false;
        }

        if (visibleQuestion) {
          return this.renderQuestionAndAnswer(
            enabledQuestion,
            question,
            formattedValues,
            translations
          );
        }
      });

    return (
      <div>
        <div className={styles["schema-title"]}>
          {schemaJson.localizationDictionary.base.title}
        </div>
        <div className={styles["form-input-area"]}>{formInputs}</div>
      </div>
    );
  }
}
