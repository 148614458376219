import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import get from "lodash.get";

//Hooks
import useDebounce from "../hooks/useDebounce";

// Translations
import i18n from "src/translations";

// Formatters
import { cellFormatters } from "src/components/tables/cell_formatters/index.js";

//Components
import { TableComponent } from "src/components/tables/table_component";
import { ButtonComponent } from "src/components/button_component";
import { TablePageLimitDropdownComponent } from "./table_page_limit_dropdown_component";
import { ComplianceContext } from "./compliance_provider_component";
import { TableNavigatorComponent } from "./table_navigator_component";
import { ComplianceBulkLockModalComponent } from "./compliance_bulk_lock_modal_component";
import { LoadingComponent } from "src/components/loading_component";

//Utils
import { camelCaseObjects } from "src/utils/camel_case_objects";

//Functions
import schemaHelpers from "src/pages/compliance/helpers/schemaHelpers.js";

// Containers
import { RevisionNavigationContainer } from "src/containers/revision_navigation_container";

export function ComplianceEvaluationsTableComponent(props) {
  const { state, dispatch } = useContext(ComplianceContext);
  const { subtypeKeysForSchema, retriggerCount, patientLinksEnabled } = props;
  const [assessmentSummaries, setAssessmentSummaries] = useState([]);
  const [selectedRevisionId, setSelectedRevisionId] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [showBulkLockModal, setShowBulkLockModal] = useState(false);
  const [showRevisionModal, setShowRevisionModal] = useState(false);
  const [isBulkLockDisabled, setIsBulkLockDisabled] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const debouncedFilters = useDebounce(state.filters, 750, setIsLoading);

  const onClickLockButton = () => setShowBulkLockModal(true);

  const onClickSelectAllButton = () => {
    let areAllAssessmentSummariesSelected =
      state.checkedAssessmentSummaries.length === assessmentSummaries.length;
    dispatch({
      type: "setCheckedAssessmentSummaries",
      payload: {
        checkedAssessmentSummaries: areAllAssessmentSummariesSelected
          ? []
          : assessmentSummaries
      }
    });
    setIsBulkLockDisabled(areAllAssessmentSummariesSelected ? true : false);
  };

  const onClickWoundLink = (e, row) => {
    let revisionId = get(row, "attributes.revisionId", "");
    setSelectedRevisionId(revisionId);
    setShowRevisionModal(true);
  };

  const onChangeCheckBox = (e, row) => {
    let checkAssessmentSummaries = state.checkedAssessmentSummaries;
    if (state.checkedAssessmentSummaries.includes(row)) {
      checkAssessmentSummaries = checkAssessmentSummaries.filter(
        assessmentSummary => {
          return assessmentSummary.id !== row.id;
        }
      );
    } else checkAssessmentSummaries.push(row);
    setIsBulkLockDisabled(checkAssessmentSummaries.length == 0);
    dispatch({
      type: "setCheckedAssessmentSummaries",
      payload: { checkedAssessmentSummaries: checkAssessmentSummaries }
    });
  };

  const hideRevisionImageModal = () => {
    if (props.revisionBeingEdited != selectedRevisionId)
      setShowRevisionModal(false);
    retriggerCount();
  };

  const hideBulkLockModal = () => {
    setIsBulkLockDisabled(true);
    dispatch({
      type: "setCheckedAssessmentSummaries",
      payload: { checkedAssessmentSummaries: [] }
    });
    setShowBulkLockModal(false);
    retriggerCount();
  };
  const url = "/api/v4/assessment-summaries";
  const columns = [
    {
      text: "Lock",
      dataField: "",
      formatter: cellFormatters.assessmentLockFormatter,
      formatExtraData: {
        currentUser: props.currentUser,
        canEditOtherUsersWork: props.canEditOtherUsersWork,
        checkedAssessmentSummaries: state.checkedAssessmentSummaries,
        eventHandler: onChangeCheckBox
      },
      hidden:
        props.selectedTab !== "signedEvaluations" || !props.canLockFromDashboard
    },
    {
      text: i18n.t("headers.patientName"),
      dataField: "firstName",
      formatter: cellFormatters.patientFirstNameLastNameFormatter,
      formatExtraData: {
        patientLinksEnabled: patientLinksEnabled
      }
    },
    {
      text: i18n.t("headers.admissionStatus"),
      dataField: "attributes.patientAdmissionStatus",
      formatter: cellFormatters.admissionStatusFormatter
    },
    {
      text: i18n.t("headers.evaluationType"),
      dataField: "evaluationType",
      formatter: cellFormatters.assessmentTypeFormatter,
      formatExtraData: {
        patientLinksEnabled: patientLinksEnabled
      }
    },
    {
      text: i18n.t("headers.dateCreated"),
      dataField: "attributes.createdAt",
      formatter: cellFormatters.dateAndTimeFormatter
    },
    {
      text: i18n.t("headers.createdBy"),
      dataField: "attributes.createdBy",
      formatter: cellFormatters.userFormatter
    },
    {
      text: i18n.t("headers.woundNumberTypeSubtype"),
      dataField: "attributes.patientWoundNumber",
      formatter: cellFormatters.woundInfoFormatter,
      formatExtraData: {
        localizationDictionary: state.schemaLocalizationDictionary,
        subtypeKeysForSchema,
        patientLinksEnabled: patientLinksEnabled,
        eventHandler: onClickWoundLink
      },
      hidden: !props.woundNumbersEnabled
    },
    {
      text: i18n.t("headers.bodyLocation"),
      // data field is required - in this case it is only used as a key
      dataField: "bodyLocation",
      formatter: cellFormatters.bodyLocationFormatter,
      formatExtraData: {
        localizationDictionary: state.schemaLocalizationDictionary,
        patientLinksEnabled: patientLinksEnabled,
        eventHandler: onClickWoundLink
      }
    },
    {
      text: i18n.t("headers.payer"),
      // data field is required - in this case it is only used as a key
      dataField: "attributes.payerNames",
      formatter: cellFormatters.payerFormatter,
      hidden: !props.payerDashboardEnabled
    },
    {
      text: "Current Wk Visit Freq",
      // data field is required - in this case it is only used as a key
      dataField: "attributes.answersJson.visitFrequency",
      formatter: cellFormatters.visitFrequencyFormatter,
      hidden: !props.visitSequenceEnabled
    },
    {
      text: i18n.t("headers.progress"),
      // data field is required - in this case it is only used as a key
      dataField: "progress",
      formatter: cellFormatters.woundProgressFormatter,
      formatExtraData: {
        assessmentVersion: props.assessmentVersion,
        eventHandler: onClickWoundLink
      }
    },
    {
      text: i18n.t("headers.revisedDate"),
      dataField: "attributes.updatedAt",
      formatter: cellFormatters.dateAndTimeFormatter
    },
    {
      text: i18n.t("headers.revisedBy"),
      dataField: "attributes.updatedBy",
      formatter: cellFormatters.userFormatter
    },
    {
      text: i18n.t("data.qaNotes.qaNote"),
      dataField: "attributes.qaNoteValue",
      formatter: cellFormatters.qaNoteFormatter,
      formatExtraData: {
        eventHandler: onClickWoundLink
      },
      hidden: !props.qaNoteAllowed
    }
  ];
  const filteredColumns = columns.filter(col => {
    return !col.hidden;
  });

  const requestPayload = () => ({
    filter: {
      ...(state.filters.evaluations.length !== 0 && {
        answers: state.filters.evaluations
      }),
      patient_admitted_and_created_between:
        state.startDate.toISOString() + ", " + state.endDate.toISOString(),
      ...(state.filters.locations.length !== 0 && {
        location_id: state.filters.locations
      }),
      lock_state:
        state.selectedTab == "completedEvaluations" ? "locked" : "unlocked",
      sign_state:
        state.selectedTab == "inProgressEvaluations" ? "unsigned" : "signed",
      name_or_mrn_or_code: state.nameOrMrnOrCode,
      ...(state.filters.payers.length !== 0 && {
        payer_ids: state.filters.payers
      }),
      ...(state.filters.clinicians.length !== 0 && {
        user_id: state.filters.clinicians
      })
    },
    page: {
      offset: state.pageLimit * state.pageNumber,
      limit: state.pageLimit
    }
  });

  const config = {
    headers: { "X-HTTP-Method-Override": "GET" }
  };

  const fetchAssessmentSummaries = async () => {
    setIsLoading(true);
    try {
      const result = await axios.post(url, requestPayload(), config);
      setAssessmentSummaries(camelCaseObjects(result.data.data));
      const count = get(result, "data.meta.record_count", 0);
      if (count != 0) setPageCount(Math.ceil(count / state.pageLimit));
      else setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      props.raiseErrorLoadingEvaluations(err);
    }
  };

  const fetchSchema = () => {
    const schema = schemaHelpers.localizationDictionaryForAssessmentSummaries(
      assessmentSummaries,
      props.schemaLocalizationDictionary
    );
    dispatch({
      type: "setSchemaLocalizationDictionary",
      payload: {
        schemaLocalizationDictionary: schema
      }
    });
  };

  useEffect(() => {
    if (showBulkLockModal == false && showRevisionModal == false) {
      fetchAssessmentSummaries();
    }
  }, [
    state.pageLimit,
    state.pageNumber,
    state.nameOrMrnOrCode,
    state.selectedTab,
    showBulkLockModal,
    showRevisionModal,
    state.startDate,
    state.endDate,
    debouncedFilters
  ]);

  useEffect(() => {
    if (assessmentSummaries.length > 0) {
      fetchSchema();
    }
  }, [assessmentSummaries]);

  useEffect(() => {
    if (!!state.schemaLocalizationDictionary) {
      setIsLoading(false);
    }
  }, [state.schemaLocalizationDictionary]);

  if (isLoading) {
    return <LoadingComponent />;
  } else {
    return (
      <div>
        {props.canLockFromDashboard &&
          assessmentSummaries.length !== 0 &&
          props.selectedTab == "signedEvaluations" && (
            <>
              {props.canEditOtherUsersWork && (
                <ButtonComponent onClick={onClickSelectAllButton}>
                  {i18n.t("actions.selectAll")}
                </ButtonComponent>
              )}
              <ButtonComponent
                onClick={onClickLockButton}
                disabled={isBulkLockDisabled}
              >
                {i18n.t("actions.lock")}
              </ButtonComponent>
            </>
          )}
        <TableComponent
          data={assessmentSummaries}
          keyField="id"
          columns={filteredColumns}
        />
        <div className="pull-left">
          <TablePageLimitDropdownComponent />
        </div>
        <TableNavigatorComponent pageCount={pageCount} />
        <Modal
          show={showRevisionModal}
          onHide={hideRevisionImageModal}
          bsSize={"lg"}
        >
          {selectedRevisionId && (
            <RevisionNavigationContainer
              revisionId={selectedRevisionId}
              revision={{ id: selectedRevisionId }}
              hide={hideRevisionImageModal}
              hyperlinkHeadersEnabled={true}
            />
          )}
        </Modal>
        <Modal
          show={showBulkLockModal}
          onHide={hideBulkLockModal}
          bsSize={"lg"}
        >
          {showBulkLockModal && (
            <ComplianceBulkLockModalComponent
              {...props}
              dictionary={state.schemaLocalizationDictionary}
              hide={hideBulkLockModal}
            />
          )}
        </Modal>
      </div>
    );
  }
}
