// React
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import moment from "moment-timezone";
import flowRight from "lodash.flowright";

// Translations
import i18n from "src/translations";

// Selectors
import { patientLinkTemplateSelector } from "src/selectors/application_state_selectors";
import {
  woundNumbersEnabledSelector,
  hprScoreEnabledSelector,
  bwatScoreEnabledSelector,
  historyCauseEnabledSelector,
  visitSequenceReportingEnabledSelector
} from "src/selectors/ui_config_selectors/global";
import { treatmentColumnEnabledSelector } from "src/selectors/ui_config_selectors/survey";

import {
  languageSelector,
  patientLinksEnabledSelector,
  partnerNameSelector,
  woundIdLinkEnabledSelector,
  assessmentVersionSelector
} from "src/selectors/config_selectors";

import {
  brandingSelector,
  brandedLogoPngSelector
} from "src/selectors/ui_config_selectors/global";

import { routeQueryParamsSelector } from "src/selectors/router_selectors";

import {
  surveyWoundDetailsLoadStateSelector,
  surveyWoundDetailsDataSelector
} from "src/selectors/data_selectors/survey_wound_details";

import { surveyWoundDetailsExportLoadStateSelector } from "src/selectors/data_selectors/exports/survey_wound_details_export";

import {
  selectedWoundTypesSelector,
  dateSelectionSelector,
  excludeNewWoundsSelector,
  selectedLocationIdsSelector,
  includeDischargedSelector
} from "src/selectors/section_selectors/survey";

import { selectedOrganizationSelector } from "src/selectors/data_selectors";

// Actions
import { loadSurveyWoundDetails } from "src/actions/data_actions/survey_wound_details";
import { createAndDownloadSurveyWoundDetailsCsv } from "src/actions/data_actions/export";
import { create } from "src/actions/flashMessageActions";

// Components
import { SurveyDetailsComponent } from "src/components/survey_details_component";

// lodash
import isEqual from "lodash.isequal";

// HOC
import { withSchemaLocalizationDictionaryWrapper } from "src/hoc/with_schema_localization_dictionary_wrapper";

// -------------- Container Class -------------- //
class DetailsContainerLoader extends Component {
  static propTypes = {
    woundDetailsLoadState: PropTypes.string.isRequired,
    woundDetailsExportLoadState: PropTypes.string.isRequired,
    woundDetails: PropTypes.array.isRequired,
    language: PropTypes.string.isRequired,
    patientLinksEnabled: PropTypes.bool.isRequired,
    partnerName: PropTypes.string.isRequired,
    patientLinkTemplate: PropTypes.string.isRequired,
    routeQueryParams: PropTypes.object.isRequired,
    selectedWoundTypes: PropTypes.array.isRequired,
    selectedLocationIds: PropTypes.array.isRequired,
    dateSelection: PropTypes.object.isRequired,
    excludeNewHealed: PropTypes.bool.isRequired,
    includeDischarged: PropTypes.bool.isRequired,
    loadDetails: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    sendCreateAndDownloadCsvAction: PropTypes.func.isRequired
  };

  componentWillMount() {
    // Check for locations being loaded
    // TODO: This should use a "locationsLoadState" prop
    if (this.props.selectedLocationIds.length) {
      this.props.loadDetails(this.getWoundDetailsPayload(this.props));
    }
  }

  componentWillReceiveProps(nextProps) {
    // TODO: use the compare_props utils module
    if (
      !isEqual(nextProps.selectedLocationIds, this.props.selectedLocationIds)
    ) {
      this.props.loadDetails(this.getWoundDetailsPayload(nextProps));
    }
  }

  /**
   * Build woundSubtypes object for payload
   * @param {Object} selectedWoundTypes
   * @return {Object} the woundSubtypes to send over ap
   */
  buildWoundSubtypesObject(selectedWoundTypes) {
    let woundSubtypes = {};

    selectedWoundTypes.forEach(option => {
      if (option.subWoundType) {
        woundSubtypes[option.woundType] = option.subWoundType;
      }
    });

    return woundSubtypes;
  }

  /**
   * Create details payload - used for loading details and csv download
   * @param {Object} props
   * @return {Object} the payload to send over api
   */
  getWoundDetailsPayload(props) {
    const {
      routeQueryParams,
      selectedWoundTypes,
      selectedLocationIds,
      excludeNewHealed,
      dateSelection,
      includeDischarged,
      language,
      selectedOrganization
    } = props;

    const { woundType, woundSubtype } = routeQueryParams;

    const woundTypeIndex = selectedWoundTypes.findIndex(
      option => option.woundType === woundType
    );

    // NOTE: This workflow currently uses a combination of route parameters and
    // survey filter selections.
    // TODO: In an ideal case, the details view would act as a standalone view
    // with its own filters that are pre-populated when navigating from the survey
    // tab. This may end up used with the wounds tab somehow.

    // If wound type specified - clicked an etiology row
    // Specify subtype from query if it was a subtype row clicked
    // Specify subtypes from selected subtypes if it was a 'total' wound row clicked
    let woundSubtypes;
    if (woundType) {
      woundSubtypes = {};
      if (woundSubtype) {
        woundSubtypes[woundType] = [woundSubtype];
      } else if (
        woundTypeIndex > -1 &&
        selectedWoundTypes[woundTypeIndex].subWoundType
      ) {
        woundSubtypes[woundType] =
          selectedWoundTypes[woundTypeIndex].subWoundType;
      }
    }

    const locale = language == "en_ca" ? "CAD" : "US";

    return {
      startDate: dateSelection.startDate.format(),
      endDate: dateSelection.endDate.format(),
      excludeNewHealed,
      age: routeQueryParams.age,
      acquired: routeQueryParams.acquired,
      resolved: routeQueryParams.resolved,
      locationsInclusive: routeQueryParams.locationsInclusive,

      locationIds: routeQueryParams.locationIds
        ? routeQueryParams.locationIds
        : selectedLocationIds,
      woundTypes: woundType
        ? [woundType]
        : selectedWoundTypes.map(option => option.woundType),
      woundSubtypes: woundSubtypes
        ? woundSubtypes
        : this.buildWoundSubtypesObject(selectedWoundTypes),
      includeDischarged: includeDischarged,
      locale,
      organizationId: selectedOrganization.id
    };
  }

  /**
   * Create a payload and call action to create and download a csv export
   */
  createAndDownloadCsv = () => {
    const payload = {
      ...this.getWoundDetailsPayload(this.props),
      timezone: moment.tz.guess()
    };

    this.props.sendCreateAndDownloadCsvAction(payload);
  };

  render() {
    return (
      <SurveyDetailsComponent
        createAndDownloadCsv={this.createAndDownloadCsv}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    woundDetailsLoadState: surveyWoundDetailsLoadStateSelector(state),
    woundDetailsExportLoadState: surveyWoundDetailsExportLoadStateSelector(
      state
    ),
    woundDetails: surveyWoundDetailsDataSelector(state),
    woundNumbersEnabled: woundNumbersEnabledSelector(state),
    language: languageSelector(state),
    patientLinksEnabled: patientLinksEnabledSelector(state),
    partnerName: partnerNameSelector(state),
    patientLinkTemplate: patientLinkTemplateSelector(state),
    routeQueryParams: routeQueryParamsSelector(state),
    selectedWoundTypes: selectedWoundTypesSelector(state),
    selectedLocationIds: selectedLocationIdsSelector(state),
    dateSelection: dateSelectionSelector(state),
    excludeNewHealed: excludeNewWoundsSelector(state),
    includeDischarged: includeDischargedSelector(state),
    woundIdLinkEnabled: woundIdLinkEnabledSelector(state),
    selectedOrganization: selectedOrganizationSelector(state),
    hprScoreEnabled: hprScoreEnabledSelector(state),
    bwatScoreEnabled: bwatScoreEnabledSelector(state),
    treatmentColumnEnabled: treatmentColumnEnabledSelector(state),
    historyCauseEnabled: historyCauseEnabledSelector(state),
    visitSequenceEnabled: visitSequenceReportingEnabledSelector(state),
    assessmentVersion: assessmentVersionSelector(state),
    branding: brandingSelector(state),
    brandedLogoPng: brandedLogoPngSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadDetails(payload) {
      dispatch(loadSurveyWoundDetails(payload));
    },
    goBack() {
      dispatch(push("/etiology"));
    },
    sendCreateAndDownloadCsvAction(payload) {
      dispatch(createAndDownloadSurveyWoundDetailsCsv(payload));
    },
    showPdfErrorView() {
      dispatch(
        create(
          i18n.t("interaction.errorMessages.pdfErrors.surveyLineList"),
          "error"
        )
      );
    }
  };
}

export const SurveyDetailsContainer = flowRight(
  withSchemaLocalizationDictionaryWrapper,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DetailsContainerLoader);
