import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";

//Translations
import i18n from "src/translations";

//Constants
import { reduxFormNames } from "src/constants";

//Styling
import styles from "./style.module.less";

//Components
import { FormSubmitComponent } from "../form_submit_component";
import { FormComponent } from "../form_component";
import { SchemaAnswersFormComponent } from "src/components/forms/schema_answers_form_component";
import { formatAnswersJson } from "./format_answers_json";

//Helpers
import { evaluatePredicate } from "src/models/assessment_answers";
import { containsRequiredFieldsForSavingAssessmentAnswers } from "src/utils/required_fields_for_save";

export class WoundAssessmentEditFormComponent extends React.PureComponent {
  static propTypes = {
    assessmentAnswer: PropTypes.object.isRequired,
    schemas: PropTypes.array.isRequired,
    formValues: PropTypes.object,
    initialValues: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.wrappedHandleSubmit = props.handleSubmit(this.submitForm);
    this.state = {
      allRequiredForSaveHaveValues: true
    };
  }

  submitForm = () => {
    const payload = this.createPayload();
    this.props.sendCreateAssessmentAnswerAction(payload).then(() => {
      this.props.cancel();
    });
  };

  createPayload = () => {
    const { assessmentAnswer, currentUser } = this.props;
    const answerJson = formatAnswersJson(this.props);
    const encounterId = get(assessmentAnswer, "attributes.encounterId", null);
    return {
      data: {
        type: "assessment_answers",
        attributes: {
          user_id: currentUser.id,
          patient_id: assessmentAnswer.attributes.patientId,
          assessment_id: assessmentAnswer.attributes.assessmentId,
          ...(encounterId && { encounter_id: encounterId }),
          resource_id: assessmentAnswer.attributes.resourceId,
          series_id: assessmentAnswer.attributes.seriesId,
          resource_type: assessmentAnswer.attributes.resourceType,
          study_id: assessmentAnswer.attributes.studyId,
          completed: true,
          assessment_answer_group_id:
            assessmentAnswer.attributes.assessmentAnswerGroupId,
          answers_json: answerJson
        }
      }
    };
  };

  evaluateQuestion = (formattedValues, predicate) => {
    const { revisionIsFirstInSeries } = this.props;
    return evaluatePredicate({
      answersJson: formattedValues,
      revisionIsFirstInSeries,
      predicate: predicate
    });
  };

  RenderFormBody = props => {
    const { handleFormSubmit, initialValues, formContentProps } = props;
    return (
      <FormComponent
        form={reduxFormNames.schemaAnswersForm}
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
      >
        {this.FormContent(formContentProps)}
      </FormComponent>
    );
  };

  FormContent = ({
    assessmentAnswer,
    schemas,
    formValues,
    cancel,
    revisionIsFirstInSeries
  }) => {
    const schemaForm = schemas.map(assessmentSchema => {
      const answersJson = formValues
        ? formValues
        : assessmentAnswer.attributes.answersJson;

      this.setState({
        allRequiredForSaveHaveValues: containsRequiredFieldsForSavingAssessmentAnswers(
          schemas,
          answersJson,
          revisionIsFirstInSeries
        )
      });

      return (
        <SchemaAnswersFormComponent
          assessmentAnswer={assessmentAnswer}
          assessmentSchema={assessmentSchema}
          revisionIsFirstInSeries={revisionIsFirstInSeries}
          formValues={formValues}
        />
      );
    });
    return (
      <div>
        <div className={styles["schema-component"]}>{schemaForm}</div>
        {this.RenderFormSubmit({ cancel })}
      </div>
    );
  };

  RenderFormSubmit = ({ cancel }) => (
    <div className="pull-right">
      <FormSubmitComponent
        disable={!this.state.allRequiredForSaveHaveValues}
        saveLabel={i18n.t("actions.save")}
        cancelLabel={i18n.t("actions.cancel")}
        cancel={cancel}
      />
    </div>
  );

  render() {
    const {
      assessmentAnswer,
      schemas,
      formValues,
      cancel,
      initialValues,
      revisionIsFirstInSeries
    } = this.props;
    const handleFormSubmit = this.wrappedHandleSubmit;
    const formContentProps = {
      assessmentAnswer,
      schemas,
      formValues,
      cancel,
      revisionIsFirstInSeries
    };
    const formBodyProps = {
      handleFormSubmit,
      initialValues,
      formContentProps
    };

    return <div>{this.RenderFormBody(formBodyProps)}</div>;
  }
}
