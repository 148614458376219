import React, { useState, useEffect, useContext } from "react";
import { FlexBox, FlexItem } from "src/components/ui_kit";
import { push } from "react-router-redux";
import createFlashMessage from "app/flash_messages/create";
import { signOut } from "src/actions/session_actions";
import axios from "axios";
import get from "lodash.get";
import { connect } from "react-redux";

//Hooks
import useDebounce from "../hooks/useDebounce";

//Translations
import i18n from "src/translations";

//CSS
import styles from "./style.module.less";

//Components
import { TabViewComponent } from "src/components/tab_view_component";
import { ComplianceEvaluationsTableComponent } from "./compliance_evaluations_table_component";
import { ComplianceContext } from "./compliance_provider_component";
import { LoadingComponent } from "src/components/loading_component";
import { CompliancePatientSearchBarComponent } from "./compliance_patient_search_bar_component";

function ComplianceEvaluationsComponent(props) {
  const { state, dispatch } = useContext(ComplianceContext);
  const [inProgressRecordCount, setInProgressRecordCount] = useState(undefined);
  const [signedRecordCount, setSignedRecordCount] = useState(undefined);
  const [completedRecordCount, setCompletedRecordCount] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [retriggerCount, setRetriggerCount] = useState(0);
  const debouncedFilters = useDebounce(state.filters, 750);

  const url = "/api/v4/assessment-summaries";

  const requestPayload = tab => ({
    filter: {
      ...(state.filters.evaluations.length !== 0 && {
        answers: state.filters.evaluations
      }),
      patient_admitted_and_created_between:
        state.startDate.toISOString() + ", " + state.endDate.toISOString(),
      ...(state.filters.locations.length !== 0 && {
        location_id: state.filters.locations
      }),
      lock_state: tab == "completedEvaluations" ? "locked" : "unlocked",
      sign_state: tab == "inProgressEvaluations" ? "unsigned" : "signed",
      name_or_mrn_or_code: state.nameOrMrnOrCode,
      ...(state.filters.payers.length !== 0 && {
        payer_ids: state.filters.payers
      }),
      ...(state.filters.clinicians.length !== 0 && {
        user_id: state.filters.clinicians
      })
    },
    page: {
      offset: 0,
      limit: 1
    }
  });

  const config = token => ({
    cancelToken: token,
    headers: {
      "X-HTTP-Method-Override": "GET"
    }
  });

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();

    const fetchAssessmentSummariesCounts = async () => {
      try {
        let [
          inProgressResult,
          signedResult,
          completedResult
        ] = await Promise.all([
          axios.post(
            url,
            requestPayload("inProgressEvaluations"),
            config(ourRequest.token)
          ),
          axios.post(
            url,
            requestPayload("signedEvaluations"),
            config(ourRequest.token)
          ),
          axios.post(
            url,
            requestPayload("completedEvaluations"),
            config(ourRequest.token)
          )
        ]);
        await Promise.all([
          setInProgressRecordCount(
            get(inProgressResult, "data.meta.record_count", undefined)
          ),
          setSignedRecordCount(
            get(signedResult, "data.meta.record_count", undefined)
          ),
          setCompletedRecordCount(
            get(completedResult, "data.meta.record_count", undefined)
          ),
          setIsLoading(false)
        ]);
      } catch (err) {
        setIsLoading(false);
        props.raiseErrorLoadingEvaluations(err);
      }
    };

    fetchAssessmentSummariesCounts();

    return () => {
      ourRequest.cancel();
    };
  }, [
    state.nameOrMrnOrCode,
    retriggerCount,
    state.startDate,
    state.endDate,
    debouncedFilters
  ]);

  const onSelectTab = value => {
    dispatch({
      type: "selectPageNumber",
      payload: { pageNumber: 0 }
    });
    dispatch({
      type: "selectTab",
      payload: { selectedTab: value }
    });
  };

  const showCount = count => {
    return count ? " (" + count + ")" : " (0)";
  };

  const tabs = [
    {
      value: "inProgressEvaluations",
      title: `${i18n.t("headers.inProgressEvaluations")} ${showCount(
        inProgressRecordCount
      )}`
    },
    {
      value: "signedEvaluations",
      title: `${i18n.t("headers.signedEvaluations")} ${showCount(
        signedRecordCount
      )}`
    },
    {
      value: "completedEvaluations",
      title: `${i18n.t("headers.completedEvaluations")} ${showCount(
        completedRecordCount
      )}`
    }
  ];

  if (isLoading) {
    return <LoadingComponent />;
  } else {
    return (
      <div>
        <FlexBox className={styles["filter-flex-box"]}>
          <FlexItem grow>
            <CompliancePatientSearchBarComponent />
          </FlexItem>
        </FlexBox>
        <TabViewComponent
          tabHeaderType="title"
          tabs={tabs}
          selectedTabValue={state.selectedTab}
          selectTab={onSelectTab}
          sizeLarge={true}
          marginClass={"marginBottom40px"}
          horizontalSpread={true}
        >
          <ComplianceEvaluationsTableComponent
            retriggerCount={() => setRetriggerCount(retriggerCount + 1)}
            selectedTab={state.selectedTab}
            {...props}
          />
        </TabViewComponent>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    raiseErrorLoadingEvaluations: err => {
      console.error(err);
      if (err.response) {
        if (err.response.status == 401) {
          dispatch(
            createFlashMessage(
              "Your session has expired. Please sign in again.",
              "error"
            )
          );

          dispatch(signOut());
          dispatch(push("/sign_in"));
        } else {
          dispatch(
            createFlashMessage(
              i18n.t("interaction.errorMessages.userActions.requestCancelled"),
              "error"
            )
          );
        }
        console.error(err.response.data);
        console.log(err.response.status);
      }
    }
  };
}

export default connect(
  () => {},
  mapDispatchToProps
)(ComplianceEvaluationsComponent);
