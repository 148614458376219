import React, { useEffect, useState } from "react";
import { ImageComponent } from "src/components/image_component";
import { ImageDeclinedContainer } from "src/containers/image_declined_container";
import PropTypes from "prop-types";
import TouchPaint from "src/helpers/canvas/TouchPaint";
import get from "lodash.get";
import { setShowAnnotationAction } from "src/actions/frame_actions";
import { annotationSelector } from "src/selectors/frame_selectors";
import { connect } from "react-redux";

function StandardFrameComponent({
  contentType,
  patientType,
  imageUrl,
  hasMeasurement,
  styles,
  region,
  imageWidth,
  imageHeight,
  showAnnotation,
  sendShowAnnotationAction
}) {
  const [canvasPainting, setCanvasPainting] = useState(null);
  const [regionCanvasRef, setRegionCanvasRef] = useState(null);

  useEffect(() => {
    if (regionCanvasRef && showAnnotation && !canvasPainting) {
      setCanvasPainting(
        new TouchPaint(regionCanvasRef, {
          width: regionCanvasRef.width,
          height: regionCanvasRef.height
        })
      );

      initRegionCanvas();
    } else if (regionCanvasRef && showAnnotation && canvasPainting) {
      canvasPainting.clear();
      initRegionCanvas();
    } else if (!showAnnotation && canvasPainting) {
      canvasPainting.clear();
    }
  }, [regionCanvasRef, showAnnotation, canvasPainting, region]);

  /**
   * Initialize the region canvas.
   * Handles all drawing of region information.
   */
  async function initRegionCanvas() {
    if (!region) return;
    if (!regionCanvasRef) return;
    if (!hasMeasurement && canvasPainting) {
      canvasPainting.clear();
      return;
    }

    let polygons = get({ region }, "region.attributes.polygons", {});

    const { measurement, undermining, tunneling, depth } = region.attributes;
    // Analysis image size is different from original image size / frame image size.
    // The frame image size is used for the units of the canvas.
    // These dimensions are used to scale the region drawing correctly.
    const analysisImgSize = get(
      { measurement },
      "measurement.regionAnalyser.originalImgSize",
      []
    );
    polygons = get(
      { measurement },
      "measurement.regionAnalyser.region.polygon",
      []
    );

    const diameterPoints = get(
        { measurement },
        "measurement.regionAnalyser.region.diameterPoints",
        []
      ),
      widthPoints = get(
        { measurement },
        "measurement.regionAnalyser.region.widthPoints",
        []
      );

    if (!canvasPainting) return;

    await canvasPainting.loadData(
      analysisImgSize,
      polygons,
      undermining.woundUndermines,
      tunneling.woundTunnels,
      depth.points,
      [diameterPoints, widthPoints]
    );
  }

  return (
    <div className={styles["frame-container"]}>
      <div className={styles["image-options"]}>
        <a
          className="btn"
          onClick={() => {
            sendShowAnnotationAction(!showAnnotation);
          }}
        >
          <span className="glyphicon glyphicon-eye-open" />
        </a>
      </div>
      <div className={styles["canvas-container"]}>
        <canvas
          ref={element => {
            setRegionCanvasRef(element);
          }}
          className={styles["region-canvas"]}
          width={imageWidth}
          height={imageHeight}
          // note that the empty style is required here because the TouchPaint class tries to access it
          style={{}}
        />
        {contentType == "declined" ? (
          <ImageDeclinedContainer displayTextHorizontal={true} />
        ) : (
          <ImageComponent
            contentType={contentType}
            patientType={patientType}
            imageUrl={imageUrl}
            haveHealX={hasMeasurement}
          />
        )}
      </div>
    </div>
  );
}

StandardFrameComponent.propTypes = {
  contentType: PropTypes.string.isRequired,
  patientType: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  hasMeasurement: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    showAnnotation: annotationSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendShowAnnotationAction: annotation => {
      dispatch(setShowAnnotationAction(annotation));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandardFrameComponent);
