import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { Tabs, Tab } from "react-bootstrap";
import { track } from "src/analytics";

// Constants
import {
  STANDARD_FRAME_TYPES,
  THERMAL_FRAME_TYPES,
  FLUORO_FRAME_TYPES
} from "../../selectors/data_selectors/frames/constants";

// Styles
import styles from "./style.module.less";

//Translations
import i18n from "src/translations";

import ThermalFrameComponent from "src/components/thermal_frame_component";
import StandardFrameComponent from "src/components/standard_frame_component";
import FluoroFrameComponent from "src/components/fluoro_frame_component";

const DEFAULT_IMAGE_HEIGHT = 1077;
const DEFAULT_IMAGE_WIDTH = 808;
const THERMAL_EVENT_KEY = "thermal";
const FLUORO_EVENT_KEY = "fluoro";

const getTrackingProperties = (frame, revision, isFromModal, isFromList) => {
  const assessmentAnswer = get(
    revision,
    "attributes.assessmentAnswers[0]",
    null
  );

  return {
    pageName: isFromModal
      ? "Compliance - Wound Modal"
      : isFromList
      ? "Patient Chart - List"
      : "Patient Chart - Comparison",
    patientID: assessmentAnswer && assessmentAnswer.patientId,
    aagID: assessmentAnswer && assessmentAnswer.assessmentAnswerGroupId,
    frameID: frame.attributes.id
  };
};

const getFrameTabs = props => {
  const {
    hasMeasurement,
    revision,
    patientType,
    frame,
    currentFrameTab,
    setCurrentFrameTab,
    region,
    isFromModal,
    isFromList
  } = props;
  const frameTabs = [];
  let defaultFrameTab = currentFrameTab;

  const standardFrame = revision.attributes.frames.find(frame =>
    STANDARD_FRAME_TYPES.includes(frame.frameType)
  );
  const deltaFrame = revision.attributes.frames.find(
    frame => THERMAL_FRAME_TYPES.DELTA === frame.frameType
  );

  const fluoroFrames = revision.attributes.frames.filter(frame =>
    FLUORO_FRAME_TYPES.includes(frame.frameType)
  );

  const contentType = get({ frame }, "frame.attributes.contentType", "");
  let imageHeight = get(frame, "attributes.imageHeight", null);
  let imageWidth = get(frame, "attributes.imageWidth", null);

  if (!imageHeight || !imageWidth) {
    imageHeight = DEFAULT_IMAGE_HEIGHT;
    imageWidth = DEFAULT_IMAGE_WIDTH;
  }

  if (standardFrame) {
    if (!defaultFrameTab) {
      defaultFrameTab = "standard";
    }

    frameTabs.push(
      <Tab eventKey={"standard"} title="Standard">
        <StandardFrameComponent
          contentType={contentType}
          hasMeasurement={hasMeasurement}
          patientType={patientType}
          imageUrl={standardFrame.imageUrl}
          styles={styles}
          region={region}
          imageHeight={imageHeight}
          imageWidth={imageWidth}
        />
      </Tab>
    );
  }

  if (deltaFrame) {
    const absoluteFrame = revision.attributes.frames.find(
      frame =>
        frame != deltaFrame && THERMAL_FRAME_TYPES.THERMAL === frame.frameType
    );

    if (!defaultFrameTab) {
      defaultFrameTab = "thermal";
    }
    frameTabs.push(
      <Tab eventKey={THERMAL_EVENT_KEY} title="Thermal">
        <ThermalFrameComponent
          contentType={contentType}
          deltaFrame={deltaFrame}
          absoluteFrame={absoluteFrame}
          styles={styles}
          region={region}
          imageHeight={imageHeight}
          imageWidth={imageWidth}
          trackingProperties={getTrackingProperties(
            frame,
            revision,
            isFromModal,
            isFromList
          )}
        />
      </Tab>
    );
  }

  if (fluoroFrames && fluoroFrames.length > 0) {
    frameTabs.push(
      <Tab eventKey={FLUORO_EVENT_KEY} title="Fluoro">
        <FluoroFrameComponent
          contentType={contentType}
          fluoroFrames={fluoroFrames}
          styles={styles}
          imageHeight={imageHeight}
          imageWidth={imageWidth}
          trackingProperties={getTrackingProperties(
            frame,
            revision,
            isFromModal,
            isFromList
          )}
        />
      </Tab>
    );
  }

  setCurrentFrameTab(defaultFrameTab);
  return frameTabs;
};

function RevisionWoundImageComponent(props) {
  const {
    frame,
    hasMeasurement,
    isFromModal,
    isFromList,
    revision,
    setCurrentFrameTab,
    currentFrameTab
  } = props;

  const [frameTabs, setFrameTabs] = useState([]);

  let imageHeight = get(frame, "attributes.imageHeight", null);
  let imageWidth = get(frame, "attributes.imageWidth", null);

  if (!imageHeight || !imageWidth) {
    imageHeight = DEFAULT_IMAGE_HEIGHT;
    imageWidth = DEFAULT_IMAGE_WIDTH;
  }

  const isPRD = get(revision, "attributes.isPrd", "");

  useEffect(() => {
    setFrameTabs(getFrameTabs(props));
  }, [props]);

  return (
    <div className={styles["revision-visuals-container"]}>
      <Tabs
        id="revision-wound-image-component-tabs"
        defaultActiveKey={currentFrameTab}
        activeKey={currentFrameTab}
        onSelect={k => {
          setCurrentFrameTab(k);
          k === THERMAL_EVENT_KEY &&
            track("Thermal Image Viewed", {
              description:
                "User has clicked on thermal tab to view the Ray1 thermal images",
              ...getTrackingProperties(frame, revision, isFromModal, isFromList)
            });
          k === FLUORO_EVENT_KEY &&
            track("Fluoro Image Viewed", {
              description:
                "User has clicked on the fluoro tab to view the Ray1 fluoro images",
              ...getTrackingProperties(frame, revision, isFromModal, isFromList)
            });
        }}
      >
        {frameTabs.map(frameTab => {
          return frameTab;
        })}
      </Tabs>
      {isFromModal && !hasMeasurement && !isPRD && (
        <div className={styles["banner-options"]}>
          <div className={styles["banner-text"]}>
            {i18n.t(`text.healXMessages.noMeasurement`)}
          </div>
        </div>
      )}
    </div>
  );
}

RevisionWoundImageComponent.propTypes = {
  revision: PropTypes.object.isRequired,
  frame: PropTypes.object,
  region: PropTypes.object,
  patientType: PropTypes.string.isRequired,
  isFromModal: PropTypes.bool
};

export { RevisionWoundImageComponent };
