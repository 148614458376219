export const DEFAULT_FRAME_TYPES = [
  "transformed",
  "original",
  "thermal",
  "delta",
  "bacterial",
  "fluoVisible",
  "fluorescent"
];
export const STANDARD_FRAME_TYPES = ["transformed", "original"];
export const THERMAL_FRAME_TYPES = { THERMAL: "thermal", DELTA: "delta" };
export const FLUORO_FRAME_TYPES = ["bacterial", "fluoVisible", "fluorescent"];
