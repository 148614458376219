import React, { useState, useEffect } from "react";
import { ImageDeclinedContainer } from "src/containers/image_declined_container";
import PropTypes from "prop-types";
import TouchPaint from "src/helpers/canvas/TouchPaint";
import get from "lodash.get";
import {
  setShowAnnotationAction,
  setIsDeltaFrameActiveAction
} from "src/actions/frame_actions";
import {
  annotationSelector,
  thermalFrameSelector
} from "src/selectors/frame_selectors";
import { connect } from "react-redux";
import i18n from "src/translations";
import { ReactComponent as DeltaSvg } from "./thermalDelta.svg";
import { ReactComponent as ThermalSvg } from "./thermalAbs.svg";
import { track } from "src/analytics";

const DEFAULT_IMAGE_HEIGHT = 533;
const DEFAULT_IMAGE_WIDTH = 400;

function ThermalFrameComponent({
  contentType,
  deltaFrame,
  absoluteFrame,
  styles,
  region,
  imageHeight,
  imageWidth,
  trackingProperties,
  showAnnotation,
  sendShowAnnotationAction,
  isDeltaFrameActive,
  sendIsDeltaFrameActiveAction
}) {
  const [regionCanvasRef, setRegionCanvasRef] = useState(null);
  const [canvasPainting, setCanvasPainting] = useState(null);
  const [imageUrl, setImageUrl] = useState(get(deltaFrame, "imageUrl", null));

  useEffect(() => {
    if (regionCanvasRef && showAnnotation && !canvasPainting) {
      setCanvasPainting(
        new TouchPaint(regionCanvasRef, {
          width: regionCanvasRef.width,
          height: regionCanvasRef.height
        })
      );

      isDeltaFrameActive ? drawDeltaCanvas() : drawRegion();
    } else if (regionCanvasRef && showAnnotation && canvasPainting) {
      canvasPainting.clear();
      isDeltaFrameActive ? drawDeltaCanvas() : drawRegion();
    } else if (!showAnnotation && canvasPainting) {
      canvasPainting.clear();
    }

    if (absoluteFrame != null) {
      setImageUrl(
        isDeltaFrameActive ? deltaFrame.imageUrl : absoluteFrame.imageUrl
      );
    }
  }, [
    isDeltaFrameActive,
    showAnnotation,
    canvasPainting,
    region,
    regionCanvasRef
  ]);

  function drawDeltaCanvas() {
    if (!region || !regionCanvasRef || !canvasPainting) {
      return;
    }

    const frame = isDeltaFrameActive ? deltaFrame : absoluteFrame;
    drawRegion();

    let frameWidth = DEFAULT_IMAGE_WIDTH;
    let frameHeight = DEFAULT_IMAGE_HEIGHT;

    const thermalMetadata = frame.imageMetadata.thermalMetadata;
    if (thermalMetadata) {
      if (thermalMetadata.originalThermalFrame) {
        frameWidth = thermalMetadata.originalThermalFrame[1][0];
        frameHeight = thermalMetadata.originalThermalFrame[1][1];
      }

      const referencePoint = thermalMetadata.referencePoint;
      const thermalPoints = thermalMetadata.thermalPoints;
      const widthRatio = imageWidth / frameWidth;
      const heightRatio = imageHeight / frameHeight;

      canvasPainting.drawCircle(
        widthRatio * referencePoint.coordinates[0],
        heightRatio * referencePoint.coordinates[1],
        referencePoint.radius,
        "Control",
        true
      );

      thermalPoints.forEach(tp => {
        const tempDiff = tp.deltaTemperature - referencePoint.deltaTemperature;
        canvasPainting.drawCircle(
          widthRatio * tp.coordinates[0],
          heightRatio * tp.coordinates[1],
          tp.radius,
          `${Math.round(tempDiff * 10) / 10} C`
        );
      });
    }
  }

  /**
   * Initialize the region canvas.
   * Handles all drawing of region information for the absolute frame.
   */
  function drawRegion() {
    if (!region || !regionCanvasRef || !canvasPainting) {
      return;
    }

    const { measurement } = region.attributes;

    // Analysis image size is different from original image size / frame image size.
    // The frame image size is used for the units of the canvas.
    // These dimensions are used to scale the region drawing correctly.
    const analysisImgSize = get(
      { measurement },
      "measurement.regionAnalyser.originalImgSize",
      []
    );

    const polygons = get(
      { measurement },
      "measurement.regionAnalyser.region.polygon",
      []
    );

    const widthRatio = analysisImgSize[0] / deltaFrame.imageWidth;
    const heightRatio = analysisImgSize[1] / deltaFrame.imageHeight;

    let diameterPoints = get(
        { measurement },
        "measurement.regionAnalyser.region.diameterPoints",
        []
      ),
      widthPoints = get(
        { measurement },
        "measurement.regionAnalyser.region.widthPoints",
        []
      );

    let thermalROI = [];
    if (deltaFrame.imageMetadata.originalMetadata) {
      thermalROI =
        deltaFrame.imageMetadata.originalMetadata.thermalRegistrationRoi[0];
    }

    canvasPainting.loadTransformedData(
      analysisImgSize,
      polygons,
      [diameterPoints, widthPoints],
      widthRatio,
      heightRatio,
      thermalROI
    );
  }

  return (
    <div style={{ height: "100%" }}>
      <div className={styles["frame-container"]}>
        <div className={styles["image-options"]}>
          <div className={styles["image-icons"]}>
            <a
              className="btn"
              onClick={() => {
                sendShowAnnotationAction(!showAnnotation);
              }}
            >
              <span className="glyphicon glyphicon-eye-open" />
            </a>
            <a
              className="btn"
              onClick={() => {
                track(
                  isDeltaFrameActive
                    ? "Absolute View Toggled"
                    : "Delta View Toggled",
                  {
                    description: isDeltaFrameActive
                      ? "User has clicked on the absolute toggle"
                      : "User has clicked on the delta toggle",
                    ...trackingProperties
                  }
                );
                sendIsDeltaFrameActiveAction(!isDeltaFrameActive);
              }}
            >
              {isDeltaFrameActive ? <DeltaSvg /> : <ThermalSvg />}
            </a>
          </div>
          <span className={styles["frame-label"]}>
            {isDeltaFrameActive
              ? i18n.t("headers.differenceView")
              : i18n.t("headers.controlView")}
          </span>
        </div>
        <div className={styles["canvas-container"]}>
          <canvas
            ref={element => {
              setRegionCanvasRef(element);
            }}
            className={styles["region-canvas"]}
            width={imageWidth}
            height={imageHeight}
          />
          {contentType == "declined" ? (
            <ImageDeclinedContainer displayTextHorizontal={true} />
          ) : (
            <img className={styles["thermal-image-container"]} src={imageUrl} />
          )}
        </div>
      </div>
      {isDeltaFrameActive && (
        <img className={styles["thermal-scale"]} src="./thermalScale.png" />
      )}
    </div>
  );
}

ThermalFrameComponent.propTypes = {
  contentType: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    showAnnotation: annotationSelector(state),
    isDeltaFrameActive: thermalFrameSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendShowAnnotationAction: annotation => {
      dispatch(setShowAnnotationAction(annotation));
    },
    sendIsDeltaFrameActiveAction: active => {
      dispatch(setIsDeltaFrameActiveAction(active));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThermalFrameComponent);
